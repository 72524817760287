<template>
  <div class="mt-5 lighter-bg py-5 ">
    <div class="offset-md-3 col-md-6 col-12 text-center">
      <h4 class="h1 font-weight-bold">Vilka brukar hyra av oss?</h4>
      <p class="mt-4">Våra kunder är allt från barnfamiljer till företag. Som vill bygga en egen pool, dränera husgrunden eller vaför inte gräva fibernät. 
        Det flesta väljer att gräva själva efter en kortare utbildning från oss för att komma ner i kostnader, men hyr inte bara maskiner utan hyr även oss att utföra arbetet.</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>



</style>