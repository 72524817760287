<template>
<div class="lighter-bg pb-5 pt-3" id="maskiner">
  <div class="dark-bg mb-5">
    <div class="pt-5 pb-4">
        <h5 class="text-center text-white h1 font-weight-bold">Maskiner</h5>
    </div>
    <div class="container">
      <div class="row mt-5 pt-4">
        <!-- CARD START -->
        <div class="col-md-6 pb-5 wow fadeInLeft" data-wow-duration="2s">
          <div class="card mb-5">
            <div class="card-body card-body-cascade text-left bg-cards">
              <div class="view view-cascade overlay z-depth-2">
                <img class="card-img-top image-size" src="../assets/TC29.jpg" alt="TC29 Excavator">
              </div>
              <div class="text-body">
                <h3 class="mb-3 font-weight-bold mt-3 theme-color">Terex TC29</h3>             
                <p class="card-text text-white">Mycket starkare och längre räckvidd med arm än Bobcat E16. <br> <br>
                  <br> 
                  Totalvikt: 2850kg
                  <br>
                  Arm räckvidd 4,81m
                  <br>
                </p>
              </div>
              <a href="#Boka" class="text-white bokaBtn btn-block text-uppercase">Till bokningen
                <i class="fas fa-arrow-down ml-1"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- CARD END -->
        <!-- CARD START -->
        <div class="col-md-6 pb-5 wow fadeInRight" data-wow-duration="2s">
          <div class="card mb-5">
            <div class="card-body card-body-cascade text-left bg-cards">
              <div class="view view-cascade overlay z-depth-2">
                <img class="card-img-top image-size" src="../assets/E16.jpg" alt="E16 Excavator">
              </div>
              <div class="text-body">
                <h3 class="mb-3 font-weight-bold mt-3 theme-color">Bobcat E16</h3>             
                <p class="card-text text-white">Lite mindre och smidigare än Terex TC29. Lättare att ta sig in i trånga områden. <br> <br class="d-none d-lg-block">
                  <br> 
                  Totalvikt: 1600kg
                  <br>
                  Arm räckvidd 4,15m
                  <br>
                </p>
              </div>
              <a href="#Boka" class="text-white bokaBtn btn-block text-uppercase">Till bokningen
                <i class="fas fa-arrow-down ml-1"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- CARD END -->
        <!-- CARD START -->
        <div class="col-md-6 pb-5 wow fadeInLeft" data-wow-duration="2s">
          <div class="card mb-5">
            <div class="card-body card-body-cascade text-left bg-cards">
              <div class="view view-cascade overlay z-depth-2">
                <img class="card-img-top image-size" src="../assets/CormidiDumper.jpg" alt="Dumper">
              </div>
              <div class="text-body">
                <h3 class="mb-3 font-weight-bold mt-3 theme-color">Dumper</h3>             
                <p class="card-text text-white">En bättre slags skottkärra med en liten skopa på samt du kan stå på en liten platta och köra den. Skopan går att tilta.
                  <br> <br>
                  Totalvikt att bära: 800kg
                  <br>
                  Enkla kontroller att lära sig
                  <br> 
                </p>
              </div>
              <a href="#Boka" class="text-white bokaBtn btn-block text-uppercase">Till bokningen
                <i class="fas fa-arrow-down ml-1"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- CARD END -->
        <!-- CARD START -->
        <div class="col-md-6 pb-5 wow fadeInRight" data-wow-duration="2s">
          <div class="card mb-5">
            <div class="card-body card-body-cascade text-left bg-cards">
              <div class="view view-cascade overlay z-depth-2">
                <img class="card-img-top image-size" src="../assets/TB23R.jpg" alt="Dumper">
              </div>
              <div class="text-body">
                <h3 class="mb-3 font-weight-bold mt-3 theme-color">TB23R</h3>             
                <p class="card-text text-white">Lite mindre Terex TC29 men större än Bobcat E16. Bra om du vill ha en mittemellan modell för ditt projekt. <br class="d-none d-lg-block">
                  <br> <br>
                  Totalvikt: 2460kg
                  <br>
                  Arm räckvidd 3,80m
                  <br>
                </p>
              </div>
              <a href="#Boka" class="text-white bokaBtn btn-block text-uppercase">Till bokningen
                <i class="fas fa-arrow-down ml-1"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- CARD END -->
      </div>
    </div>
  </div>

  <div class="jumbotron jumbotron-fluid my-2" id="prislista">
    <div class="container">
      <div class="text-center">
        <p class="h1 font-weight-bold">Prislista</p>
        <p class="lead">Alla priser nedan är exclusive moms.</p>
      </div>


      <div class="row">
        <div class="p-2 col-12 col-md-12">
          <table>
              <th class="title"><i class="fas fa-clock theme-color"></i> Tid</th>
              <th class="title">Pris:</th>
              <th class="title"><span class="theme-color">Bobcat E16</span></th>
              <th class="title"><span class="theme-color">Terex TC29</span></th>
              <tbody>
                <tr>
                  <td>1 Dygn</td>
                  <td></td>
                  <td>1000 kr</td>
                  <td class="custom-padding">1400 kr</td>
                </tr>
                <tr>
                  <td>Fredag eftermiddag - Söndag kväll</td>
                  <td></td>
                  <td>2000 kr</td>
                  <td class="custom-padding">2800 kr</td>
                </tr>
                <tr>
                  <td>1 Vecka (Hyr 7 dygn till priset av 6 dygn)</td>
                  <td></td>
                  <td>6000 kr</td>
                  <td class="custom-padding">8400 kr</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
          </table>
        </div>

        <!-- LEVERANS -->
        <div class="p-2 col-12 col-md-6">
          <table>
              <th class="title"><i class="fas fa-truck theme-color"></i> Leverans eller Hämtning</th>
              <th class="title">Pris</th>
              <tbody>
                <tr>
                  <td>Norrort</td>
                  <td>600 kr</td>
                </tr>
                <tr>
                  <td>Söderort</td>
                  <td>900 kr</td>
                </tr>
                <tr>
                  <td>Mälardalen</td>
                  <td>Varierar</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
          </table>
        </div>
        <div class="p-2 col-12 col-md-6">
          <th class="title"><i class="fas fa-hard-hat theme-color"></i> Hyr arbetare</th>
          <table class="">
            Det finns även möjlighet till att hyra en arbetare till att köra maskinen du hyr. <br> Erbjuder även att köra lastbil eller liknande jobb. Priset för detta varierar, <a href="#Boka"><span class="theme-color">kontakta oss!</span></a>
            <p class="mt-2">
              <span class="theme-color font-weight-bold">Terex TC29</span> 650kr/h 
              <br> 
              <span class="theme-color font-weight-bold">Övriga maskiner</span> 600kr/h</p>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>

.bokaBtn {
  background: linear-gradient(to bottom, rgb(243, 141, 7), rgb(247, 91, 1));
  padding: 15px;
  font-weight: 600;
  font-size: 14px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
} .bokaBtn:hover {
  box-shadow: 0px 0px 20px 2px rgb(243, 141, 7);  
  color: white;
}

h1 {
  font-weight: bold;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

table {
  width:100%;
  font-size: 15px;
}

tr {
  border-top:1px solid black
}

th {
  text-align:left;
  font-size: 15px;
}

th,td {
  padding:10px;
  font-size: 15px;
}

.custom-padding {
  padding: 0;
  padding-left: 9px;
}

.image-size {
  height: 250px;
}

@media (min-width: 768px) {  
  .custom-padding {
    padding: 15px;
  }
}
@media (min-width: 992px) {  
  .image-size {
    height: 300px;
  }
}
@media (min-width: 1200px) {  
  .image-size {
    height: 350px;
  }
}

.card {
  background-color: transparent;
  border-radius: 10px;
}

.card-text {
  font-size: 16px !important;
}

.card-body {
  border-radius: 6px;
  min-height: 460px;
  padding: 0;
}

.text-body {
  padding: 0px 20px 20px 20px;
}

.view {
  margin-top: -4rem;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.bg-cards {
  background: linear-gradient(to top, rgb(32, 32, 32), rgb(27, 27, 27));
  box-shadow: 0px 5px 20px 10px rgba(0, 0, 0, 0.219);  
}

</style>