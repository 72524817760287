<template>
  <div class="mt-5 mb-5">
    <div class="offset-md-3 col-md-6 col-12 text-center">
      <h2 class="title-color h1 font-weight-bold">Företaget</h2>
      <p class="mt-4">Sollentuna Bygguthyrning grundades år 2006 och är lokaliserat i Solltentuna men vi hyr ut över hela Stockholm. Vi hyr ut till både företag och privatpersoner.
        Företaget har f-skattsedel och färdskrivarkort. Vi har C-körkort, CE-körkort samt YKB utbildning med lastbilsmonterad kran över 18 tonmeter. 
      </p>
      <img class="sizeimg img-fluid" src="../assets/bygga.png" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>


<style scoped>

h1 {
  font-weight: bold;
}

.sizeimg {
  width: 400px;
}

</style>
